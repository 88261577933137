<!--出入库明细-->
<div class="InOutDetail"></div>
<template>
    <div class="InOutDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" v-model="form" size="small" label-width="80px">
                <el-row>
                    <ef-dept-type-and-dept-select @selectDeptType="setDeptType" @selectDept="setDept" />
                    <el-col :span="8">
                        <el-form-item label="业务类型">
                            <el-select v-model="form.bizType" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="item in meta.bizTypes"
                                    :label="item.value"
                                    :value="item.key"
                                    :key="item.key"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="是否结账">
                            <el-select placeholder="是否结账" v-model="form.settleStatus">
                                <el-option value="" label="请选择" />
                                <el-option value="0" label="未结账" />
                                <el-option value="1" label="已结账" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="单号">
                            <el-input placeholder="单号" v-model="form.bizCode" style="width: 220px" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="关键字">
                            <el-input placeholder="商品名称/条码/自编码" v-model="form.search" style="width: 220px" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="类目">
                            <el-select v-model="form.categoryCode">
                                <el-option label="请选择" value="" />
                                <el-option-group v-for="group in meta.categories" :label="group.name" :key="group.id">
                                    <el-option
                                        v-for="category in group.sub"
                                        :label="category.name"
                                        :value="category.code"
                                        :key="category.code"
                                    />
                                </el-option-group>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="品牌">
                            <el-select v-model="form.brandCode">
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="item in meta.brands"
                                    :label="item.name"
                                    :value="item.code"
                                    :key="item.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-button
                type="primary"
                @click="handleManualQuery"
                size="small"
                v-if="hasPrivilege('menu.report.inOutDetail.open')"
                >查询
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.report.inOutDetail.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="400"
            >
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="单号" width="170" prop="bizCode" v-if="showColumn('bizCode')" />
                <el-table-column
                    label="机构名称"
                    width="200"
                    prop="deptName"
                    fixed="left"
                    v-if="showColumn('deptName')"
                />
                <el-table-column
                    label="商品名称"
                    width="200"
                    prop="sku.name"
                    fixed="left"
                    v-if="showColumn('sku.name')"
                />
                <el-table-column
                    label="商品条码"
                    width="144"
                    prop="sku.bars"
                    key="sku.bars"
                    v-if="showColumn('sku.bars')"
                >
                    <template slot-scope="scope">
                        <span v-for="b in scope.row.sku.bars || []" :key="b">{{ b }}<br /></span>
                    </template>
                </el-table-column>
                <el-table-column label="自编码" width="80" prop="sku.customBar" v-if="showColumn('sku.customBar')" />
                <el-table-column label="单位" width="80" prop="sku.unit" v-if="showColumn('sku.unit')" />
                <el-table-column label="规格" width="120" prop="sku.specs" v-if="showColumn('sku.specs')" />
                <el-table-column label="类目" width="120" prop="sku.category" v-if="showColumn('sku.category')" />
                <el-table-column label="品牌" width="120" prop="sku.brand" v-if="showColumn('sku.brand')" />
                <el-table-column label="业务类型" width="120" prop="bizType" key="bizType" v-if="showColumn('bizType')">
                    <template slot-scope="scope">
                        <span>{{ scope.row.bizType | bizTypeName }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="日期" width="120" prop="date" v-if="showColumn('date')" />
                <el-table-column
                    label="是否结账"
                    width="80"
                    prop="settleStatus"
                    key="settleStatus"
                    v-if="showColumn('settleStatus')"
                >
                    <template slot-scope="scope">
                        {{ scope.row.settleStatus ? '已结账' : '未结账' }}
                    </template>
                </el-table-column>
                <el-table-column label="进项数量" width="100" prop="inCount" v-if="showColumn('inCount')" />
                <el-table-column
                    label="进项成本金额"
                    width="100"
                    prop="inCostMoney"
                    key="inCostMoney"
                    v-if="showColumn('inCostMoney') && showPlanCostPrice()"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.inCostMoney | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="进项金额"
                    width="100"
                    prop="inMoney"
                    key="inMoney"
                    v-if="showColumn('inMoney') && showInPrice()"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.inMoney | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="出项数量" width="100" prop="outCount" v-if="showColumn('outCount')" />
                <el-table-column
                    label="出项成本金额"
                    width="100"
                    prop="outCostMoney"
                    key="outCostMoney"
                    v-if="showColumn('outCostMoney') && showPlanCostPrice()"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.outCostMoney | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="出项金额"
                    width="100"
                    prop="outMoney"
                    key="outMoney"
                    v-if="showColumn('outMoney') && showOutPrice()"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.outMoney | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="出入库操作人" width="100" prop="creatorName" v-if="showColumn('creatorName')" />
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import BizTypeUtils from 'js/BizTypeUtils';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'ReportInOutDetail',
    components: { EfEndDate, EfStartDate, EfDeptTypeAndDeptSelect, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                deptType: '',
                deptCode: '',
                bizType: '',
                settleStatus: '',
                bizCode: '',
                search: '',
                categoryCode: '',
                brandCode: '',
                startTime: '',
                endTime: '',
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            url: {
                page: '/report/inOutDetail',
            },
            meta: {
                bizTypes: [],
                categories: [],
                brands: [],
            },
        };
    },
    mounted() {
        const _this = this;
        UrlUtils.GoodsBrands(_this, (data) => {
            _this.meta.brands = data;
        });
        UrlUtils.GroupedGoodsCategories(_this, (rst) => {
            _this.meta.categories = rst;
        });
    },
    methods: {
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleManualQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleExport() {
            UrlUtils.Export(this, '出入库明细', '/report/inOutDetail/export', this.form);
        },
        mFour: Util.mFour,
        mCount: Util.mCount,
        setDept(deptCode, deptName, deptType) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
            this.meta.bizTypes = BizTypeUtils.bizTypes(deptType);
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
            if (deptType) {
                this.handleQuery();
            }
        },
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
    },
    filters: {
        bizTypeName(type) {
            return BizTypeUtils.name(type);
        },
    },
};
</script>
